import React from "react"
import * as footerStyles from "./footer.module.scss"

export function Footer() {
  return (
    <div className={footerStyles.container}>
      Created with Gatsby. © 2021 Alyssa Thi
    </div>
  )
}
